import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrivacyPolicy } from './components/privacyPolicy';
import { TermsOfService } from './components/termsOfService';
import { VolXSpacePlayer } from './components/volXSpacePlayer';
import { VolXSpacePlayerInternal } from './components/volXSpacePlayerInternal';

import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <div>404 Not Found</div>,
    element: <VolXSpacePlayer content="https://voluspace.b-cdn.net/default_content.json" fullscreen="true" />,
  },
  {
    path: "/holoyiff",
    element: <VolXSpacePlayer content="https://voluspace.b-cdn.net/holoyiff/content.json" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-ios" />,
  },
  {
    path: "/holoyiff/9",
    element: <VolXSpacePlayer content="https://voluspace.b-cdn.net/holoyiff/content.json" info-link-1="https://x.com/HoloYiff/status/1807474008629334076" fullscreen="true" id="9" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-ios%3Fid%3D9" />,
  },
  {
    path: "/holoyiff/11",
    element: <VolXSpacePlayer content="https://voluspace.b-cdn.net/holoyiff/content.json" info-link-1="https://x.com/holoyiff" fullscreen="true" id="11" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-ios%3Fid%3D11" />,
  },
  {
    path: "/holoyiff/internal",
    element: <VolXSpacePlayerInternal content="https://voluspace.b-cdn.net/holoyiff/content_internal.json" fullscreen="true" ios-redirect="https://launchar.app/launch/volxspace?url=https%3A%2F%2Fvolxspace.netlify.app%2Fholoyiff-internal-ios" />,
  },
  {
    path: "/terms",
    element: <TermsOfService />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
